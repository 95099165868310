import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

//const routes = constructRoutes(document.querySelector('#single-spa-layout'));

const routes = constructRoutes(microfrontendLayout);

//singleSpa.setBootstrapMaxTime(3000);

// System.import('single-spa').then(({ registerApplication, start }) => {
//   registerApplication({
//     name: 'app1',
//     app: () => System.import('app1'),
//     activeWhen: location => location.pathname.startsWith('/app1'),
//   });

//   registerApplication({
//     name: 'app2',
//     app: () => System.import('app2'),
//     activeWhen: location => location.pathname.startsWith('/app2'),
//   });

//   start();
// });

// const data = {
//   loaders: {
//     loading: `<nav class="placeholder"></nav>`
//   },
//   props: {
//     token: ''
//   }
// }

//const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    console.log('rootconfig inside loadApp', name)
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });
console.log('rootconfig', applications)
applications.forEach(registerApplication);
layoutEngine.activate();
start();
